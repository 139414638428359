import React from 'react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Banner = ({ data }) => {
  /*const settings = {
    fade: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
  };*/

  return (
    <div className="billboard-row">
      <div className='ptrack-container billboard-presentation-tracking'>
        <div className='billboard-presentation-tracking ptrack-content'>

            {data.map((item) => (

              <div key={item.id} className="billboard billboard-pane billboard-originals trailer-billboard">
                <div className="billboard-motion dismiss-mask">
                  <div className="motion-background-component bottom-layer full-screen">
                    <div className="hero-image-wrapper">
                      <img className="hero static-image image-layer" src={process.env.PUBLIC_URL + item.image} alt={item.title}/>
                      <div className="trailer-vignette vignette-layer">
                      </div>
                      <div className="hero-vignette vignette-layer">
                      </div>
                      <div className="embedded-components button-layer">
                      </div>
                    </div>
                  </div>
                </div>

                <div className="fill-container">
                  <div className="info meta-layer">
                    <div className="logo-and-text meta-layer">
                      <div className="titleWrapper">
                        <div className="billboard-title">
                          <div className='d-flex align-items-center'>
                            <img className='' src={process.env.PUBLIC_URL + 'img/iso-pretitle.png'} alt={item.title} />
                            <h5>Cursos</h5>
                          </div>
                          <h1 className='title'>{item.title}</h1>
                        </div>
                          <h2>{item.subtitle}</h2>
                      </div>
                      <div className="info-wrapper">
                        <div className="info-wrapper-fade">
                          <div className="episode-title-container">
                          </div><div className="synopsis-fade-container">
                            <div className="synopsis no-supplemental">
                              <div className="ptrack-content">
                              {item.description}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="billboard-links button-layer forward-leaning">
                        <a href={item.play} className="playLink isToolkit" aria-label="Reproducir">
                          <button className="color-primary hasLabel hasIcon ltr-podnco" type="button">
                            <div className="ltr-1st24vv">
                              <div className="medium ltr-iyulz3">
                                <img src={process.env.PUBLIC_URL + 'img/icons/play.svg'} alt={item.title}/>
                              </div>
                            </div>
                            <div className="ltr-1npqywr">
                            </div>
                            <span className="ltr-1vh9doa">Reproducir</span>
                          </button>
                        </a>
                        <button className="color-secondary hasLabel hasIcon ltr-18ezbm2" data-uia="billboard-more-info" type="button">
                          <div className="ltr-1st24vv">
                            <div className="medium ltr-iyulz3">
                              <img src={process.env.PUBLIC_URL + 'img/icons/info.svg'} alt={item.title}/>
                            </div>
                          </div>
                          <div className="ltr-1npqywr">
                          </div>
                          <span className="ltr-1vh9doa">Más información</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Banner;
