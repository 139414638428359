// Courses.js
import React from 'react';

const Courses = () => {
  return (
    <div>
      <h2>Cursos</h2>
      {/* Contenido de la página de cursos */}
    </div>
  );
};

export default Courses;
