// DropdownMenu.js
import React, { useState, useRef } from 'react';

const DropdownMenu = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const dropdownRef = useRef(null);
    let timeoutId;
    let timeoutActive;

    const handleMouseEnter = () => {
        setShowMenu(true);
        timeoutActive = setTimeout(() => {
            setIsActive(true)
        }, 300)
    };

    const handleMouseLeave = () => {
        timeoutActive = setTimeout(() => {
            setIsActive(false)
        }, 100);
        // Agrega un retraso de 300 milisegundos antes de ocultar el menú
        timeoutId = setTimeout(() => {
            setShowMenu(false);
        }, 300);
    };

    const handleMenuEnter = () => {
        // Cancela el retraso si el mouse entra al menú
        clearTimeout(timeoutId);
    };

    const handleMenuLeave = () => {
        timeoutActive = setTimeout(() => {
            setIsActive(false)
        }, 100);
        // Oculta el menú cuando el mouse sale del menú
        timeoutId = setTimeout(() => {
            setShowMenu(false);
        }, 300);
    };

    return (
        <div className={`account-menu-item ${showMenu ? 'active' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {/* Contenido del botón personalizado */}
            <div className="account-dropdown-button">
                <a href="#" aria-label="Cuenta y configuración">
                    <span className="profile-link" role="presentation">
                        <img className="profile-icon" src={process.env.PUBLIC_URL + '/img/profile-icon.png'} alt="profile-icon" />
                    </span>
                </a>
                <span className={`caret ${showMenu ? 'open' : ''}`} role="presentation">
                </span>
            </div>

            {/* Menú desplegable */}
            {showMenu && (
                <div
                    className={`account-drop-down sub-menu theme-lakira ${isActive ? 'visible' : ''}`}
                    ref={dropdownRef}
                    onMouseEnter={handleMenuEnter}
                    onMouseLeave={handleMenuLeave}
                >
                    <div className="ptrack-content">

                        <ul className="account-links sub-menu-list" aria-label="Cuenta">

                            <li className="sub-menu-item">
                                <a className="sub-menu-link sub-menu-link-icon" href="#">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="ltr-4z3qvp e1svuwfo1" data-name="User" aria-hidden="true">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15 5C15 6.65685 13.6569 8 12 8C10.3431 8 9 6.65685 9 5C9 3.34315 10.3431 2 12 2C13.6569 2 15 3.34315 15 5ZM17 5C17 7.76142 14.7614 10 12 10C9.23858 10 7 7.76142 7 5C7 2.23858 9.23858 0 12 0C14.7614 0 17 2.23858 17 5ZM4 21C4 16.5817 7.58172 13 12 13C16.4183 13 20 16.5817 20 21V21.5136C19.5678 21.5667 18.9844 21.6327 18.2814 21.6988C16.6787 21.8495 14.461 22 12 22C9.53901 22 7.32131 21.8495 5.71861 21.6988C5.01564 21.6327 4.43224 21.5667 4 21.5136V21ZM21.1508 23.3775C21.1509 23.3774 21.151 23.3774 21 22.3889L21.151 23.3774C21.6393 23.3028 22 22.8829 22 22.3889V21C22 15.4772 17.5228 11 12 11C6.47715 11 2 15.4772 2 21V22.3889C2 22.8829 2.36067 23.3028 2.84897 23.3774L3 22.3889C2.84897 23.3774 2.84908 23.3774 2.8492 23.3775L2.84952 23.3775L2.85043 23.3776L2.85334 23.3781L2.86352 23.3796L2.90103 23.3852C2.93357 23.3899 2.98105 23.3968 3.04275 23.4055C3.16613 23.4228 3.3464 23.4472 3.57769 23.4765C4.04018 23.535 4.7071 23.6126 5.5314 23.6901C7.1787 23.8449 9.461 24 12 24C14.539 24 16.8213 23.8449 18.4686 23.6901C19.2929 23.6126 19.9598 23.535 20.4223 23.4765C20.6536 23.4472 20.8339 23.4228 20.9573 23.4055C21.0189 23.3968 21.0664 23.3899 21.099 23.3852L21.1365 23.3796L21.1467 23.3781L21.1496 23.3776L21.1505 23.3775L21.1508 23.3775Z" fill="currentColor">
                                        </path>
                                    </svg>
                                    <span>Cuenta</span>
                                </a>
                            </li>
                            <li className="sub-menu-item">
                                <a className="sub-menu-link sub-menu-link-icon" href="#">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="ltr-4z3qvp e1svuwfo1" data-name="CircleQuestionMark" aria-hidden="true"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM12 8C10.6831 8 10 8.74303 10 9.5H8C8 7.25697 10.0032 6 12 6C13.9968 6 16 7.25697 16 9.5C16 10.8487 14.9191 11.7679 13.8217 12.18C13.5572 12.2793 13.3322 12.4295 13.1858 12.5913C13.0452 12.7467 13 12.883 13 13V14H11V13C11 11.5649 12.1677 10.6647 13.1186 10.3076C13.8476 10.0339 14 9.64823 14 9.5C14 8.74303 13.3169 8 12 8ZM13.5 16.5C13.5 17.3284 12.8284 18 12 18C11.1716 18 10.5 17.3284 10.5 16.5C10.5 15.6716 11.1716 15 12 15C12.8284 15 13.5 15.6716 13.5 16.5Z" fill="currentColor">
                                </path>
                                </svg>
                                <span>Centro de ayuda</span>
                            </a>
                            </li>
                        </ul>
                        <ul className="sub-menu-list sign-out-links">
                            <li className="sub-menu-item">
                                <a className="sub-menu-link " href="#">Cerrar sesión en Masflix</a>
                            </li>
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DropdownMenu;
