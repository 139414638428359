// Chapters.js
import React from 'react';

const Chapters = () => {
  return (
    <div>
      <h2>Capítulos</h2>
      {/* Contenido de la página de capítulos */}
    </div>
  );
};

export default Chapters;
