import React from 'react';
import Header from '../components/header';
import Banner from '../components/banner';
import Carousel from '../components/carousel';
import data from '../data/data.json';
import SearchPage from './SearchPage';

const Home = () => {
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);
    
  const { carousel, videos } = data;

  // Filtrar videos por categoría
  const ourSelection = videos.find((video) => video.title === 'selections');
  const trends = videos.find((video) => video.title === 'trends');

  return (
    <div className='bd dark-background'>
      <Header setIsSearchOpen={setIsSearchOpen} />
      {isSearchOpen ? (
        <SearchPage setIsSearchOpen={setIsSearchOpen} />
      ) : (
        <div className='mainView'>
          <div className='lolomo is-fullbleed'>

        <Banner className='volatile-billboard-animations-container' data={carousel} />
        <Carousel title="Nuestra Selección" data={ourSelection.contents} itemsPerSlide={7} />
        <Carousel title="Tendencias" data={trends.contents} itemsPerSlide={7} />
          </div>
      </div>
      )}
    </div>
  );
};

export default Home;
