import React, { useState, useEffect } from 'react';
import SearchPage from '../pages/SearchPage';
import DropdownMenu from './DropdownMenu';

const Header = () => {
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleSearchIconClick = () => {
        setIsSearchOpen((prev) => !prev);
    };

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const pinningHeader = {
        width: '100%',
        position: 'fixed',
        top: '0',
        minHeight: '1px',
        zIndex: '1',
        background: scrollPosition > 0 ? '#141414' : 'transparent',
        transition: 'ease-in-out .5s',
        // Otros estilos pueden ser añadidos aquí
    };

    return (
        <header className='pinning-header' style={pinningHeader}>
            <div className='pinning-header-container'>
                <div className='main-header has-billboard menu-navigation'>
                    <a href='#' className='logo active'>
                        <img src={process.env.PUBLIC_URL + '/logo-masflix.svg'} alt="Logo" />
                    </a>

                    <ul className="tabbed-primary-navigation">
                        {/* ... (resto del código) */}
                    </ul>
                    <div className="secondary-navigation">
                        <div className="nav-element" onClick={handleSearchIconClick}>
                            <div className='searchBox'>
                                <button className='searchTab'>
                                    <img src={process.env.PUBLIC_URL + 'img/icons/magnify.svg'} alt="Search" />
                                </button>
                            </div>
                        </div>
                        {isSearchOpen && <SearchPage setIsSearchOpen={setIsSearchOpen} />}
                        <div className='nav-element'>
                            <div className='notifications'>
                                <button className='notifications-menu'>
                                    <img src={process.env.PUBLIC_URL + 'img/icons/notificacion.svg'} />
                                </button>
                            </div>
                        </div>
                        <DropdownMenu className="nav-element" />
                        {/* Add dropdown content for user */}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
