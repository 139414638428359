// Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import data from '../data/data.json';
import '../assets/styles/Login-base.css';
import '../assets/styles/Login.css';

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    rememberMe: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simular llamada a la API para obtener usuarios
    const users = await fetchUsersFromAPI(); // Implementar esta función según tus necesidades

    // Validación de las credenciales
    const isValidCredentials = users.some(
      (user) =>
        user.username === formData.username &&
        user.password === formData.password
    );

    if (isValidCredentials) {
      login(formData);
      navigate('/');
    } else {
      alert('Usuario o contraseña incorrectos');
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'rememberMe' ? checked : value,
    }));
  };

  // Simular llamada a la API para obtener usuarios
  const fetchUsersFromAPI = async () => {
    // Aquí puedes realizar la lógica para obtener los usuarios de la API o servidor
    // En este ejemplo, usaremos los usuarios del archivo data.json
    return data.users;
  };

  return (

    <div id="login-masflix">
      <div id="appMountPoint">
        <div class="login-wrapper hybrid-login-wrapper">
          <div class="login-wrapper-background">
            <img class="concord-img vlv-creative" src={process.env.PUBLIC_URL + 'img/bg-login.jpg'} alt="" /></div>
          <div class="nfHeader login-header signupBasicHeader">
            <a href="#" class="svg-nfLogo signupBasicHeader">
              <img classNameName='svg-icon svg-icon-netflix-logo' src={process.env.PUBLIC_URL + 'logo-masflix.svg'} alt='logo'/>
              <span class="screen-reader-text">Inicio de Masflix</span>
            </a>
          </div>
          <div class="login-body">
            <div>
              <div class="login-content login-form hybrid-login-form hybrid-login-form-signup">
                <div class="hybrid-login-form-main">
                  <h1>Inicia sesión</h1>
                  <form method="post" class="login-form" action="" onSubmit={handleSubmit}>
                    <div class="nfInput nfEmailPhoneInput login-input login-input-email">
                      <div class="nfInputPlacement">
                        <div class="nfEmailPhoneControls">

                          <label class="input_id" placeholder="">
                            <input
                              type="text"
                              name="username"
                              class="nfTextField"
                              id="id_userLoginId"
                              placeholder=""
                              value={formData.username}
                              onChange={handleChange}
                            />
                            <label for="id_userLoginId" class="placeLabel">Email o número de teléfono</label>
                          </label>

                        </div>
                      </div>
                    </div>
                    <div class="nfInput nfPasswordInput login-input login-input-password">
                      <div class="nfInputPlacement">
                        <div class="nfPasswordControls">
                          <label class="input_id" placeholder="">
                            <input
                              type="password"
                              name="password"
                              class="nfTextField"
                              id="id_password"
                              placeholder=""
                              value={formData.password}
                              onChange={handleChange}
                            />
                            <label for="id_password" class="placeLabel">Contraseña</label>
                          </label>
                          <button id="id_password_toggle" type="button" class="nfPasswordToggle" title="Mostrar contraseña">MOSTRAR</button>
                        </div>
                      </div>
                    </div>
                    <button class="btn login-button btn-submit btn-small" type="submit" autocomplete="off" tabindex="0">Iniciar sesión</button>
                    <div class="hybrid-login-form-help">
                      <div class="ui-binary-input login-remember-me">
                        <input
                          type="checkbox"
                          name="rememberMe"
                          id="bxid_rememberMe_true"
                          value="true"
                          tabindex="0"
                          checked={formData.rememberMe}
                          onChange={handleChange}
                        />
                        <label for="bxid_rememberMe_true">
                          <span class="login-remember-me-label-text">Recuérdame</span>
                        </label>
                        <div class="helper"></div>
                      </div>
                      <a class="login-help-link link" href="#">¿Necesitas ayuda?</a>
                    </div>

                  </form>
                </div>
                <div class="hybrid-login-form-other">
                  <div class="login-signup-now">¿Primera vez en Masflix? <a href="#">Contáctanos</a>.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
