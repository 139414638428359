import React, { useState } from 'react';
import Slider from 'react-slick';
import { OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carousel = ({ title, data, itemsPerSlide }) => {
  const [modalStates, setModalStates] = useState(data.map(() => false));

  const handleShow = (index) => {
    const updatedModalStates = [...modalStates];
    updatedModalStates[index] = true;
    setModalStates(updatedModalStates);
  };

  const handleClose = (index) => {
    const updatedModalStates = [...modalStates];
    updatedModalStates[index] = false;
    setModalStates(updatedModalStates);
  };

  const addlist = "Agregar a Mi lista";
  const like = "Me gusta";
  const dislike = "No es para mí";
  const chaptersInfo = "Capítulos e Info";
  const tooltipStyle = {
    backgroundColor: '#fff',
    color: '#000',
    borderRadius: '4px',
    fontSize: '17px',
    padding: '3px 7px',
    marginBottom: '10px'
  };
  const settings = {
    infinite: false,
    dots: true,
    dotsClass: 'pagination-indicator',
    slidesToShow: itemsPerSlide || 1,
    slidesToScroll: itemsPerSlide,
    prevArrow: <span aria-label="Ver cursos anteriores"><b className="indicator-icon icon-leftCaret"></b></span>,
    nextArrow: <span aria-label="Ver más cursos"><b className="indicator-icon icon-rightCaret"></b></span>,
  };


  return (
    <div className="lolomoRow lolomoRow_title_card">
      <h2 className="rowHeader">
        <a href="#" className="rowTitle ">
          <div className="row-header-title">{title}</div>
          <div className="aro-row-header more-visible">
            <div className="see-all-link">Explorar todos</div>
            <div className="aro-row-chevron icon-akiraCaretRight"></div>
          </div>
        </a>
      </h2>
      <div className='rowContainer rowContainer_title_card'>
        <div className='ptrack-container'>
          <div className='rowContent slider-hover-trigger-layer'>
            <Slider {...settings}>
              {data.map((item, index) => (
                <React.Fragment key={item.id}>
                  <div key={item.id} className="title-card-container">
                    <div className='ptrack-content'>
                      <a href="#" aria-label={item.title} className="slider-refocus">
                        <div className="boxart-size-16x9 boxart-container boxart-rounded">
                          <img className="boxart-image boxart-image-in-padded-container" src={process.env.PUBLIC_URL + item.image} alt={item.title} />
                          <div className="fallback-text-container">
                            <p className="fallback-text">{item.title}</p>
                          </div>
                        </div>
                        {item.new && <span className='new-label'>Nuevo Curso</span>}
                      </a>
                    </div>
                  </div>

                  <div class="focus-trap-wrapper previewModal--wrapper mini-modal has-smaller-buttons">
                    <div class="previewModal--container mini-modal has-smaller-buttons">
                      <div class="previewModal--player_container mini-modal has-smaller-buttons">
                        <div style={{ position: 'absolute', width: '100%', height: '100%', overflow: 'hidden' }}>
                          <div id={item.id} style={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}>
                            <video src=""></video>
                            <div class="player-timedtext"></div>
                          </div>
                        </div>
                        <div class="videoMerchPlayer--boxart-wrapper" style={{ position: 'static' }}>
                          <img class="previewModal--boxart" src={process.env.PUBLIC_URL + item.image} alt={item.title} />
                        </div>
                        <div>
                          <div class="previewModal--player-titleTreatmentWrapper">
                            <div class="previewModal--player-titleTreatment-left previewModal--player-titleTreatment mini-modal has-smaller-buttons mini-modal has-smaller-buttons">
                              <h1>{item.title}</h1>
                            </div>
                            <div class="mini-modal has-smaller-buttons previewModal-audioToggle" style={{ opacity: 0, display: 'none' }}>
                              <div class="global-supplemental-audio-toggle">
                                <button aria-label="Activar el audio" class="color-supplementary hasIcon round ltr-11vo9g5" type="button">
                                  <div class="ltr-1st24vv">
                                    <div class="small">
                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="ltr-4z3qvp e1svuwfo1" data-name="VolumeOff" aria-hidden="true">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11 4.00003C11 3.59557 10.7564 3.23093 10.3827 3.07615C10.009 2.92137 9.57889 3.00692 9.29289 3.29292L4.58579 8.00003H1C0.447715 8.00003 0 8.44774 0 9.00003V15C0 15.5523 0.447715 16 1 16H4.58579L9.29289 20.7071C9.57889 20.9931 10.009 21.0787 10.3827 20.9239C10.7564 20.7691 11 20.4045 11 20V4.00003ZM5.70711 9.70714L9 6.41424V17.5858L5.70711 14.2929L5.41421 14H5H2V10H5H5.41421L5.70711 9.70714ZM15.2929 9.70714L17.5858 12L15.2929 14.2929L16.7071 15.7071L19 13.4142L21.2929 15.7071L22.7071 14.2929L20.4142 12L22.7071 9.70714L21.2929 8.29292L19 10.5858L16.7071 8.29292L15.2929 9.70714Z" fill="currentColor"></path>
                                      </svg>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="previewModal--info" style={{ opacity: 1, transform: 'none' }}>
                        <a href="#">
                          <div class="mini-modal-container">
                            <div class="previewModal--info-container">
                              <div class="previewModal--metadatAndControls mini-modal has-smaller-buttons">
                                <div class="previewModal--metadatAndControls-container">
                                  <div class="buttonControls--container mini-modal has-smaller-buttons">
                                    <a href="#" class="primary-button playLink isToolkit isRound" aria-label="Reproducir">
                                      <button class="color-primary hasIcon round ltr-jewi6s" type="button">
                                        <div class="icon-wrap">
                                          <div class="small">
                                            <img src={process.env.PUBLIC_URL + 'img/icons/play.svg'} alt={item.title}/>
                                          </div>
                                        </div>
                                      </button>
                                    </a>
                                    <div class="ltr-bjn8wh">
                                      <div class="ptrack-content">
                                        <OverlayTrigger overlay={<Tooltip style={tooltipStyle}>{addlist}</Tooltip>} placement="top">
                                          <button aria-label="Agregar a Mi lista" class="color-supplementary hasIcon round" type="button">
                                            <div class="icon-wrap">
                                              <div class="small">
                                                <i className='icon-plus'></i>
                                              </div>
                                            </div>
                                          </button>
                                        </OverlayTrigger>
                                      </div>
                                    </div>
                                    <div class="ltr-179t5g5 like-btn">

                                      <button class="color-supplementary hasIcon round" type="button" aria-haspopup="menu" aria-controls="#thumbs-selection-menu" aria-expanded="false">
                                        <div class="icon-wrap">
                                          <div class="small">
                                            <i className='icon-thumb-up-line'></i>
                                          </div>
                                        </div>
                                      </button>

                                      <div class="thumbs-selection-overlay-container">
                                        <div id="thumbs-selection-menu">
                                          <div>
                                            <div class="thumb-popover">
                                              <OverlayTrigger overlay={<Tooltip style={tooltipStyle}>{like}</Tooltip>} placement="top">
                                                <button aria-label="Calificación Me gusta" class="color-supplementary small round" id="thumbs-selection-up" type="button">
                                                  <i className='icon-thumb-up-line'></i>
                                                </button>
                                              </OverlayTrigger>
                                              <OverlayTrigger overlay={<Tooltip style={tooltipStyle}>{dislike}</Tooltip>} placement="top">
                                                <button aria-label="Calificación No me gusta" class="color-supplementary small round" id="thumbs-selection-down" type="button">
                                                  <i className='icon-thumb-down-line'></i>
                                                </button>
                                              </OverlayTrigger>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                    <div class="buttonControls--expand-button ltr-bjn8wh">
                                      <OverlayTrigger overlay={<Tooltip style={tooltipStyle}>{chaptersInfo}</Tooltip>} placement="top">
                                        <button onClick={() => handleShow(index)} class="color-supplementary hasIcon round ltr-11vo9g5" aria-label="expand to detail modal" type="button">
                                          <div class="icon-wrap">
                                            <div class="small">
                                              <i className='icon-uniE757'></i>
                                            </div>
                                          </div>
                                        </button>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                  <div class="previewModal--metadatAndControls-info" style={{ opacity: 1 }}>
                                    <div>
                                      <div class="">
                                        <div class="videoMetadata--container">
                                          <div class="videoMetadata--second-line">
                                            <span class="match-score">{item.percent}% para ti</span>
                                            <span class="maturity-rating ">
                                              <span class="player-feature-badge">{item.quality}</span>
                                            </span>
                                            <span class="duration">{item.chapter} Cápitulos</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="previewModal--metadatAndControls-tags-container" style={{ opacity: 1 }}>
                                    <div class="evidence-tags">
                                      <div class="evidence-list">
                                        <div class="evidence-item">
                                          <span class="evidence-text">{item.information[0]}</span>
                                        </div><div class="evidence-item">
                                          <span class="evidence-separator"></span>
                                          <span class="evidence-text">{item.information[1]}</span>
                                        </div>
                                        <div class="evidence-item">
                                          <span class="evidence-separator"></span>
                                          <span class="evidence-text">{item.information[2]}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <Modal show={modalStates[index]} onHide={() => handleClose(index)} dialogClassName="modal-masflix">
                    <div className='focus-trap-wrapper previewModal--wrapper detail-modal has-smaller-buttons'>


                      <div className='previewModal--container detail-modal has-smaller-buttons'>
                        <div class="previewModal--player_container detail-modal has-smaller-buttons">
                          <div style={{ position: 'absolute', width: '100%', height: '100%', overflow: 'hidden' }}>
                            <div id={item.id} style={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}>
                              <video src="" style={{ position: 'absolute', width: '100%', height: '100%', objectFfit: 'cover' }}></video>
                              <div class="player-timedtext" style={{ display: 'none', direction: 'ltr' }}>

                              </div>
                            </div>
                          </div>
                          <div class="videoMerchPlayer--boxart-wrapper" style={{ position: 'absolute' }}>
                            <img class="previewModal--boxart" src="" aria-hidden="true" alt={item.title} style={{ opacity: 0 }} />
                            <img alt={item.title} src={process.env.PUBLIC_URL + 'img/modal-thumbnail/1.jpg'} aria-hidden="true" style={{ display: 'none' }} /></div>
                          <div class="storyArt detail-modal has-smaller-buttons">
                            <img src={process.env.PUBLIC_URL + 'img/modal-thumbnail/1.jpg'} alt={item.title} class="playerModel--player__storyArt detail-modal has-smaller-buttons" style={{ opacity: 1 }} />
                            <img alt={item.title} src={process.env.PUBLIC_URL + 'img/modal-thumbnail/1.jpg'} aria-hidden="true" style={{ display: 'none' }} /></div>
                          <div style={{ opacity: 1 }}>
                            <div class="previewModal--player-titleTreatmentWrapper" style={{ opacity: 1 }}>
                              <div class="previewModal--player-titleTreatment-left previewModal--player-titleTreatment detail-modal has-smaller-buttons detail-modal has-smaller-buttons">
                                <div className="modal-title">
                                  <div className='d-flex align-items-center'>
                                    <img className='' src={process.env.PUBLIC_URL + 'img/iso-pretitle.png'} alt={item.title}/>
                                    <h5>Cursos</h5>
                                  </div>
                                  <h1 className='title'>{item.title}</h1>
                                </div>
                                <div class="buttonControls--container">
                                  <a href="#" class="primary-button playLink isToolkit" aria-label="Reproducir">
                                    <button class="color-primary hasLabel hasIcon ltr-podnco" type="button">
                                      <div class="ltr-1st24vv">
                                        <div class="medium ltr-iyulz3" role="presentation">
                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="ltr-4z3qvp e1svuwfo1" data-name="Play" aria-hidden="true">
                                            <path d="M5 2.69127C5 1.93067 5.81547 1.44851 6.48192 1.81506L23.4069 11.1238C24.0977 11.5037 24.0977 12.4963 23.4069 12.8762L6.48192 22.1849C5.81546 22.5515 5 22.0693 5 21.3087V2.69127Z" fill="currentColor"></path>
                                          </svg>
                                        </div>
                                      </div>
                                      <div class="ltr-1npqywr" style={{ width: '1rem' }}></div>
                                      <span class="ltr-1vh9doa">Reproducir</span>
                                    </button>
                                  </a>
                                  <div class="ltr-bjn8wh">
                                    <div class="ptrack-content">
                                      <OverlayTrigger overlay={<Tooltip style={tooltipStyle}>{addlist}</Tooltip>} placement="top">
                                        <button aria-label="Agregar a Mi lista" class="color-supplementary hasIcon round" type="button">
                                          <div class="icon-wrap">
                                            <div class="small">
                                              <i className='icon-plus'></i>
                                            </div>
                                          </div>
                                        </button>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                  <div class="ltr-179t5g5 like-btn">

                                    <button class="color-supplementary hasIcon round" type="button" aria-haspopup="menu" aria-controls="#thumbs-selection-menu" aria-expanded="false">
                                      <div class="icon-wrap">
                                        <div class="small">
                                          <i className='icon-thumb-up-line'></i>
                                        </div>
                                      </div>
                                    </button>

                                    <div class="thumbs-selection-overlay-container">
                                      <div id="thumbs-selection-menu">
                                        <div>
                                          <div class="thumb-popover">
                                            <OverlayTrigger overlay={<Tooltip style={tooltipStyle}>{like}</Tooltip>} placement="top">
                                              <button aria-label="Calificación Me gusta" class="color-supplementary small round" id="thumbs-selection-up" type="button">
                                                <i className='icon-thumb-up-line'></i>
                                              </button>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip style={tooltipStyle}>{dislike}</Tooltip>} placement="top">
                                              <button aria-label="Calificación No me gusta" class="color-supplementary small round" id="thumbs-selection-down" type="button">
                                                <i className='icon-thumb-down-line'></i>
                                              </button>
                                            </OverlayTrigger>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                                <div class="buttonControls--messaging"></div>
                              </div>
                              <div class="detail-modal has-smaller-buttons previewModal-audioToggle" style={{ opacity: 0.4, display: 'block' }}>
                                <div class="global-supplemental-audio-toggle">
                                  <button aria-label="Activar el audio" class="color-supplementary hasIcon round ltr-11vo9g5" type="button">
                                    <div class="ltr-1st24vv">
                                      <div class="small ltr-iyulz3" role="presentation">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="ltr-4z3qvp e1svuwfo1" data-name="VolumeOff" aria-hidden="true">
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11 4.00003C11 3.59557 10.7564 3.23093 10.3827 3.07615C10.009 2.92137 9.57889 3.00692 9.29289 3.29292L4.58579 8.00003H1C0.447715 8.00003 0 8.44774 0 9.00003V15C0 15.5523 0.447715 16 1 16H4.58579L9.29289 20.7071C9.57889 20.9931 10.009 21.0787 10.3827 20.9239C10.7564 20.7691 11 20.4045 11 20V4.00003ZM5.70711 9.70714L9 6.41424V17.5858L5.70711 14.2929L5.41421 14H5H2V10H5H5.41421L5.70711 9.70714ZM15.2929 9.70714L17.5858 12L15.2929 14.2929L16.7071 15.7071L19 13.4142L21.2929 15.7071L22.7071 14.2929L20.4142 12L22.7071 9.70714L21.2929 8.29292L19 10.5858L16.7071 8.29292L15.2929 9.70714Z" fill="currentColor"></path>
                                        </svg>
                                      </div>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="previewModal-close" onClick={() => handleClose(index)}>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="ltr-4z3qvp e1svuwfo1" data-name="X" aria-labelledby="preview-modal-70177057" role="button" aria-label="close">
                            <title id="preview-modal-70177057">close</title>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5858 12L2.29291 3.70706L3.70712 2.29285L12 10.5857L20.2929 2.29285L21.7071 3.70706L13.4142 12L21.7071 20.2928L20.2929 21.7071L12 13.4142L3.70712 21.7071L2.29291 20.2928L10.5858 12Z" fill="currentColor"></path>
                          </svg>
                        </div>
                        <div class="previewModal--info" style={{ opacity: 1, transform: 'none' }}>
                          <div class="detail-modal-container">
                            <div class="ptrack-container">
                              <div>
                                <div class="previewModal--detailsMetadata detail-modal has-smaller-buttons">
                                  <div class="previewModal--detailsMetadata-left">
                                    <div class="previewModal--detailsMetadata-info">
                                      <div>
                                        <div class="">
                                          <div class="videoMetadata--container">
                                            <div class="videoMetadata--first-line">
                                              <span class="match-score-wrapper">
                                                <div class="show-match-score rating-inner">
                                                  <div class="meta-thumb-container thumb-down">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="thumb thumb-down-filled svg-icon svg-icon-thumb-thumb-down-filled ltr-4z3qvp e1svuwfo1" data-name="ThumbsDownFill" aria-hidden="true">
                                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.593 17.7442L10.687 18.4959C10.8658 19.9265 12.0819 21 13.5236 21C13.7867 21 14 20.7867 14 20.5236V17.2868C14 17.0994 14.0527 16.9157 14.152 16.7568L16.0926 13.6519C16.3548 13.2323 16.7633 12.9248 17.2391 12.7888L19.2747 12.2072C19.704 12.0846 20 11.6922 20 11.2457V5.68387C20 5.30618 19.6938 5 19.3161 5C18.126 5 16.9565 4.68942 15.9232 4.09895L15.75 4C14.6032 3.34469 13.3053 3 11.9844 3H11H8H7.5C6.67157 3 6 3.67157 6 4.5C6 4.88418 6.14443 5.23462 6.38195 5.5H6C5.17157 5.5 4.5 6.17157 4.5 7C4.5 7.53991 4.78525 8.0132 5.21328 8.27737C4.522 8.41118 4 9.01963 4 9.75C4 10.5784 4.67157 11.25 5.5 11.25H5.67055C5.26638 11.5187 5 11.9783 5 12.5C5 13.3284 5.67157 14 6.5 14H10.875L10.593 16.2558C10.5312 16.75 10.5312 17.25 10.593 17.7442Z" fill="currentColor"></path>
                                                    </svg>
                                                  </div>
                                                  <div class="meta-thumb-container thumb-up">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="thumb thumb-up-filled svg-icon svg-icon-thumb-thumb-up-filled ltr-4z3qvp e1svuwfo1" data-name="ThumbsUpFill" aria-hidden="true">
                                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.407 6.25579L13.313 5.50407C13.1342 4.07353 11.9181 3 10.4764 3C10.2133 3 10 3.21331 10 3.47644V6.7132C10 6.90062 9.94733 7.08427 9.848 7.2432L7.90742 10.3481C7.64516 10.7677 7.23665 11.0752 6.76086 11.2112L4.72528 11.7928C4.29598 11.9154 4 12.3078 4 12.7543V18.3161C4 18.6938 4.30618 19 4.68387 19C5.874 19 7.04352 19.3106 8.07684 19.9011L8.25 20C9.39679 20.6553 10.6947 21 12.0156 21H13H16H16.5C17.3284 21 18 20.3284 18 19.5C18 19.1158 17.8556 18.7654 17.6181 18.5H18C18.8284 18.5 19.5 17.8284 19.5 17C19.5 16.4601 19.2147 15.9868 18.7867 15.7226C19.478 15.5888 20 14.9804 20 14.25C20 13.4216 19.3284 12.75 18.5 12.75H18.3294C18.7336 12.4813 19 12.0217 19 11.5C19 10.6716 18.3284 10 17.5 10H13.125L13.407 7.74421C13.4688 7.24999 13.4688 6.75001 13.407 6.25579Z" fill="currentColor"></path>
                                                    </svg>
                                                  </div>
                                                  <span class="match-score">{item.percent}% para ti</span>
                                                </div>
                                              </span>
                                            </div>
                                            <div class="videoMetadata--second-line">
                                              <span class="player-feature-badge">{item.quality}</span>
                                              <span class="duration">{item.chapter} Capitulos</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="ltr-7t0zr9">
                                          <div class="previewModal--metadatAndControls-tags-container" style={{ opacity: 1 }}>
                                            <div class="evidence-tags">
                                              <div class="evidence-list">
                                                <div class="evidence-item">
                                                  <span class="evidence-text">{item.information[0]}</span>
                                                </div><div class="evidence-item">
                                                  <span class="evidence-separator"></span>
                                                  <span class="evidence-text">{item.information[1]}</span>
                                                </div>
                                                <div class="evidence-item">
                                                  <span class="evidence-separator"></span>
                                                  <span class="evidence-text">{item.information[2]}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="ltr-s5xdrg"></div>
                                    <p class="preview-modal-synopsis previewModal--text">
                                      <div class="ptrack-content">Sumérgete en "Técnicas de Venta Telefónica", un capítulo cautivante de la serie sobre seguros. Descubre cómo persuadir y cerrar acuerdos a distancia para el éxito comercial.</div>
                                    </p>
                                  </div>
                                  <div class="previewModal--detailsMetadata-right">
                                    <div class="previewModal--tags">
                                      <span class="previewModal--tags-label">Habilidades de Venta Remota: </span>
                                      <span class="">
                                        <p>Aprende estrategias efectivas para persuadir y cerrar ventas por teléfono.</p>
                                      </span>
                                    </div>
                                    <div class="previewModal--tags">
                                      <span class="previewModal--tags-label">Enfoque en el Sector de Seguros:</span>
                                      <span class="">
                                        <p>Adquiere conocimientos específicos para el campo de seguros.</p>
                                      </span>
                                    </div>
                                    <div class="previewModal--tags">
                                      <span class="previewModal--tags-label">Aplicación Práctica Inmediata: </span>
                                      <span class="">
                                        <p>Cada lección te brindará herramientas prácticas, permitiéndote aplicar lo aprendido de manera inmediata.</p>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="ptrack-container">
                              <div class="ptrack-content">
                                <div class="episodeSelector">
                                  <div class="episodeSelector-header">
                                    <div class="ltr-1fttcpj">
                                      <h3 class="previewModal--section-header episodeSelector-label show-single-season">Capitulos</h3>
                                      <div class="chapter-detail">
                                        <span class="allEpisodeSelector-season-label chapters-course">Módulo 1:</span>
                                        <span class="ltr-1q4vxyr">Seguro de Vida</span>
                                      </div>
                                    </div>
                                    <div class="episodeSelector-dropdown">
                                      <div class="ltr-179t5g5">
                                        <button aria-expanded="false" aria-haspopup="true" aria-label="dropdown-menu-trigger-button" class="dropdown-toggle ltr-111bn9j">Módulo 1</button></div>
                                    </div>
                                  </div>
                                  <div class="episodeSelector-container">
                                    <div class="titleCardList--container episode-item current" aria-label="Prepárate antes de cada llamada">
                                      <div class="titleCard-title_index">1</div>
                                      <div class="titleCard-imageWrapper">
                                        <div class="ptrack-content">
                                          <img src={process.env.PUBLIC_URL + 'img/chapter-thumbnail/1.jpg'} alt={item.title} /></div>
                                        <div class="titleCard-playIcon">
                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="titleCard-playSVG ltr-4z3qvp e1svuwfo1" data-name="Play" aria-hidden="true">
                                            <path d="M5 2.69127C5 1.93067 5.81547 1.44851 6.48192 1.81506L23.4069 11.1238C24.0977 11.5037 24.0977 12.4963 23.4069 12.8762L6.48192 22.1849C5.81546 22.5515 5 22.0693 5 21.3087V2.69127Z" fill="currentColor"></path>
                                          </svg>
                                        </div>
                                      </div>
                                      <div class="titleCardList--metadataWrapper">
                                        <div class="titleCardList-title"><span class="titleCard-title_text">Prepárate antes de cada llamada</span>
                                          <span>
                                            <span class="duration ellipsized">7 min</span>
                                          </span>
                                        </div>
                                        <p class="titleCard-synopsis previewModal--small-text">
                                          <div class="ptrack-content">Sumérgete en "Técnicas de Venta Telefónica", un capítulo cautivante de la serie sobre seguros. Descubre cómo persuadir y cerrar acuerdos a distancia para el éxito financiero.</div>
                                        </p>
                                      </div>
                                    </div>
                                    <div class="titleCardList--container episode-item current" aria-label="Prepárate antes de cada llamada">
                                      <div class="titleCard-title_index">1</div>
                                      <div class="titleCard-imageWrapper">
                                        <div class="ptrack-content">
                                          <img src={process.env.PUBLIC_URL + 'img/chapter-thumbnail/1.jpg'} alt={item.title} /></div>
                                        <div class="titleCard-playIcon">
                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="titleCard-playSVG ltr-4z3qvp e1svuwfo1" data-name="Play" aria-hidden="true">
                                            <path d="M5 2.69127C5 1.93067 5.81547 1.44851 6.48192 1.81506L23.4069 11.1238C24.0977 11.5037 24.0977 12.4963 23.4069 12.8762L6.48192 22.1849C5.81546 22.5515 5 22.0693 5 21.3087V2.69127Z" fill="currentColor"></path>
                                          </svg>
                                        </div>
                                      </div>
                                      <div class="titleCardList--metadataWrapper">
                                        <div class="titleCardList-title"><span class="titleCard-title_text">Prepárate antes de cada llamada</span>
                                          <span>
                                            <span class="duration ellipsized">7 min</span>
                                          </span>
                                        </div>
                                        <p class="titleCard-synopsis previewModal--small-text">
                                          <div class="ptrack-content">Sumérgete en "Técnicas de Venta Telefónica", un capítulo cautivante de la serie sobre seguros. Descubre cómo persuadir y cerrar acuerdos a distancia para el éxito financiero.</div>
                                        </p>
                                      </div>
                                    </div>
                                    <div class="titleCardList--container episode-item current" aria-label="Prepárate antes de cada llamada">
                                      <div class="titleCard-title_index">1</div>
                                      <div class="titleCard-imageWrapper">
                                        <div class="ptrack-content">
                                          <img src={process.env.PUBLIC_URL + 'img/chapter-thumbnail/1.jpg'} alt={item.title} /></div>
                                        <div class="titleCard-playIcon">
                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="titleCard-playSVG ltr-4z3qvp e1svuwfo1" data-name="Play" aria-hidden="true">
                                            <path d="M5 2.69127C5 1.93067 5.81547 1.44851 6.48192 1.81506L23.4069 11.1238C24.0977 11.5037 24.0977 12.4963 23.4069 12.8762L6.48192 22.1849C5.81546 22.5515 5 22.0693 5 21.3087V2.69127Z" fill="currentColor"></path>
                                          </svg>
                                        </div>
                                      </div>
                                      <div class="titleCardList--metadataWrapper">
                                        <div class="titleCardList-title"><span class="titleCard-title_text">Prepárate antes de cada llamada</span>
                                          <span>
                                            <span class="duration ellipsized">7 min</span>
                                          </span>
                                        </div>
                                        <p class="titleCard-synopsis previewModal--small-text">
                                          <div class="ptrack-content">Sumérgete en "Técnicas de Venta Telefónica", un capítulo cautivante de la serie sobre seguros. Descubre cómo persuadir y cerrar acuerdos a distancia para el éxito financiero.</div>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="ptrack-container">
                              <div class="ptrack-content">
                                <div class="ptrack-container">
                                  <div class="moreLikeThis--wrapper">
                                    <h3 class="previewModal--section-header moreLikeThis--header">Más cursos similares a este</h3>
                                    <div class="section-container collapsed" style={{ maxHeight: '65em' }}>
                                      <div class="moreLikeThis--container">

                                        <div class="titleCard--container more-like-this-item" aria-label="1">
                                          <div class="titleCard-imageWrapper has-duration">
                                            <div class="ptrack-content">
                                              <img src={process.env.PUBLIC_URL + 'img/thumbnails/1.jpg'} alt="1" />
                                            </div>
                                            <div class="titleCard-playIcon">
                                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="titleCard-playSVG ltr-4z3qvp e1svuwfo1" data-name="Play" aria-hidden="true">
                                                <path d="M5 2.69127C5 1.93067 5.81547 1.44851 6.48192 1.81506L23.4069 11.1238C24.0977 11.5037 24.0977 12.4963 23.4069 12.8762L6.48192 22.1849C5.81546 22.5515 5 22.0693 5 21.3087V2.69127Z" fill="currentColor"></path>
                                              </svg>
                                            </div>
                                            <span class="duration ellipsized">8 capítulos</span>
                                          </div>
                                          <div class="titleCard--metadataWrapper">
                                            <div>
                                              <div class="videoMetadata--container-container">
                                                <div class="videoMetadata--container titlecard-videoMetadata videoMetadata--two-lines">
                                                  <div class="videoMetadata--first-line">
                                                    <span class="match-score-wrapper no-rating">
                                                      <div class="show-match-score rating-inner">
                                                        <div class="meta-thumb-container thumb-down">
                                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="thumb thumb-down-filled svg-icon svg-icon-thumb-thumb-down-filled ltr-4z3qvp e1svuwfo1" data-name="ThumbsDownFill" aria-hidden="true">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.593 17.7442L10.687 18.4959C10.8658 19.9265 12.0819 21 13.5236 21C13.7867 21 14 20.7867 14 20.5236V17.2868C14 17.0994 14.0527 16.9157 14.152 16.7568L16.0926 13.6519C16.3548 13.2323 16.7633 12.9248 17.2391 12.7888L19.2747 12.2072C19.704 12.0846 20 11.6922 20 11.2457V5.68387C20 5.30618 19.6938 5 19.3161 5C18.126 5 16.9565 4.68942 15.9232 4.09895L15.75 4C14.6032 3.34469 13.3053 3 11.9844 3H11H8H7.5C6.67157 3 6 3.67157 6 4.5C6 4.88418 6.14443 5.23462 6.38195 5.5H6C5.17157 5.5 4.5 6.17157 4.5 7C4.5 7.53991 4.78525 8.0132 5.21328 8.27737C4.522 8.41118 4 9.01963 4 9.75C4 10.5784 4.67157 11.25 5.5 11.25H5.67055C5.26638 11.5187 5 11.9783 5 12.5C5 13.3284 5.67157 14 6.5 14H10.875L10.593 16.2558C10.5312 16.75 10.5312 17.25 10.593 17.7442Z" fill="currentColor"></path>
                                                          </svg>
                                                        </div>
                                                        <div class="meta-thumb-container thumb-up">
                                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="thumb thumb-up-filled svg-icon svg-icon-thumb-thumb-up-filled ltr-4z3qvp e1svuwfo1" data-name="ThumbsUpFill" aria-hidden="true">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.407 6.25579L13.313 5.50407C13.1342 4.07353 11.9181 3 10.4764 3C10.2133 3 10 3.21331 10 3.47644V6.7132C10 6.90062 9.94733 7.08427 9.848 7.2432L7.90742 10.3481C7.64516 10.7677 7.23665 11.0752 6.76086 11.2112L4.72528 11.7928C4.29598 11.9154 4 12.3078 4 12.7543V18.3161C4 18.6938 4.30618 19 4.68387 19C5.874 19 7.04352 19.3106 8.07684 19.9011L8.25 20C9.39679 20.6553 10.6947 21 12.0156 21H13H16H16.5C17.3284 21 18 20.3284 18 19.5C18 19.1158 17.8556 18.7654 17.6181 18.5H18C18.8284 18.5 19.5 17.8284 19.5 17C19.5 16.4601 19.2147 15.9868 18.7867 15.7226C19.478 15.5888 20 14.9804 20 14.25C20 13.4216 19.3284 12.75 18.5 12.75H18.3294C18.7336 12.4813 19 12.0217 19 11.5C19 10.6716 18.3284 10 17.5 10H13.125L13.407 7.74421C13.4688 7.24999 13.4688 6.75001 13.407 6.25579Z" fill="currentColor"></path>
                                                          </svg>
                                                        </div>
                                                      </div>
                                                    </span>
                                                  </div>
                                                  <div class="videoMetadata--second-line">
                                                    <div class="year">2023</div>
                                                    <span class="maturity-rating "><span class="maturity-number">HD</span></span>
                                                  </div>
                                                </div>
                                                <div>
                                                  <div class="ltr-bjn8wh">
                                                    <div class="ptrack-content">
                                                      <button aria-label="Agregar a Mi lista" class="color-supplementary hasIcon round ltr-11vo9g5" type="button">
                                                        <div class="ltr-1st24vv">
                                                          <div class="small ltr-iyulz3">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="ltr-4z3qvp e1svuwfo1" data-name="Plus" aria-hidden="true">
                                                              <path fill-rule="evenodd" clip-rule="evenodd" d="M11 11V2H13V11H22V13H13V22H11V13H2V11H11Z" fill="currentColor"></path>
                                                            </svg>
                                                          </div>
                                                        </div>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <p class="titleCard-synopsis previewModal--small-text">
                                              <div class="ptrack-container">
                                                <div class="ptrack-content">Aprende la ruta de los 5 pasos clave para garantizar ventas exitosas. Potencia tus habilidades en ventas y alcanza el éxito.</div>
                                              </div>
                                            </p>
                                          </div>
                                        </div>

                                        <div class="titleCard--container more-like-this-item" aria-label="1">
                                          <div class="titleCard-imageWrapper has-duration">
                                            <div class="ptrack-content">
                                              <img src={process.env.PUBLIC_URL + 'img/thumbnails/2.jpg'} alt="1" />
                                            </div>
                                            <div class="titleCard-playIcon">
                                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="titleCard-playSVG ltr-4z3qvp e1svuwfo1" data-name="Play" aria-hidden="true">
                                                <path d="M5 2.69127C5 1.93067 5.81547 1.44851 6.48192 1.81506L23.4069 11.1238C24.0977 11.5037 24.0977 12.4963 23.4069 12.8762L6.48192 22.1849C5.81546 22.5515 5 22.0693 5 21.3087V2.69127Z" fill="currentColor"></path>
                                              </svg>
                                            </div>
                                            <span class="duration ellipsized">12 capítulos</span>
                                          </div>
                                          <div class="titleCard--metadataWrapper">
                                            <div>
                                              <div class="videoMetadata--container-container">
                                                <div class="videoMetadata--container titlecard-videoMetadata videoMetadata--two-lines">
                                                  <div class="videoMetadata--first-line">
                                                    <span class="match-score-wrapper no-rating">
                                                      <div class="show-match-score rating-inner">
                                                        <div class="meta-thumb-container thumb-down">
                                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="thumb thumb-down-filled svg-icon svg-icon-thumb-thumb-down-filled ltr-4z3qvp e1svuwfo1" data-name="ThumbsDownFill" aria-hidden="true">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.593 17.7442L10.687 18.4959C10.8658 19.9265 12.0819 21 13.5236 21C13.7867 21 14 20.7867 14 20.5236V17.2868C14 17.0994 14.0527 16.9157 14.152 16.7568L16.0926 13.6519C16.3548 13.2323 16.7633 12.9248 17.2391 12.7888L19.2747 12.2072C19.704 12.0846 20 11.6922 20 11.2457V5.68387C20 5.30618 19.6938 5 19.3161 5C18.126 5 16.9565 4.68942 15.9232 4.09895L15.75 4C14.6032 3.34469 13.3053 3 11.9844 3H11H8H7.5C6.67157 3 6 3.67157 6 4.5C6 4.88418 6.14443 5.23462 6.38195 5.5H6C5.17157 5.5 4.5 6.17157 4.5 7C4.5 7.53991 4.78525 8.0132 5.21328 8.27737C4.522 8.41118 4 9.01963 4 9.75C4 10.5784 4.67157 11.25 5.5 11.25H5.67055C5.26638 11.5187 5 11.9783 5 12.5C5 13.3284 5.67157 14 6.5 14H10.875L10.593 16.2558C10.5312 16.75 10.5312 17.25 10.593 17.7442Z" fill="currentColor"></path>
                                                          </svg>
                                                        </div>
                                                        <div class="meta-thumb-container thumb-up">
                                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="thumb thumb-up-filled svg-icon svg-icon-thumb-thumb-up-filled ltr-4z3qvp e1svuwfo1" data-name="ThumbsUpFill" aria-hidden="true">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.407 6.25579L13.313 5.50407C13.1342 4.07353 11.9181 3 10.4764 3C10.2133 3 10 3.21331 10 3.47644V6.7132C10 6.90062 9.94733 7.08427 9.848 7.2432L7.90742 10.3481C7.64516 10.7677 7.23665 11.0752 6.76086 11.2112L4.72528 11.7928C4.29598 11.9154 4 12.3078 4 12.7543V18.3161C4 18.6938 4.30618 19 4.68387 19C5.874 19 7.04352 19.3106 8.07684 19.9011L8.25 20C9.39679 20.6553 10.6947 21 12.0156 21H13H16H16.5C17.3284 21 18 20.3284 18 19.5C18 19.1158 17.8556 18.7654 17.6181 18.5H18C18.8284 18.5 19.5 17.8284 19.5 17C19.5 16.4601 19.2147 15.9868 18.7867 15.7226C19.478 15.5888 20 14.9804 20 14.25C20 13.4216 19.3284 12.75 18.5 12.75H18.3294C18.7336 12.4813 19 12.0217 19 11.5C19 10.6716 18.3284 10 17.5 10H13.125L13.407 7.74421C13.4688 7.24999 13.4688 6.75001 13.407 6.25579Z" fill="currentColor"></path>
                                                          </svg>
                                                        </div>
                                                      </div>
                                                    </span>
                                                  </div>
                                                  <div class="videoMetadata--second-line">
                                                    <div class="year">2023</div>
                                                    <span class="maturity-rating "><span class="maturity-number">HD</span></span>
                                                  </div>
                                                </div>
                                                <div>
                                                  <div class="ltr-bjn8wh">
                                                    <div class="ptrack-content">
                                                      <button aria-label="Agregar a Mi lista" class="color-supplementary hasIcon round ltr-11vo9g5" type="button">
                                                        <div class="ltr-1st24vv">
                                                          <div class="small ltr-iyulz3">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="ltr-4z3qvp e1svuwfo1" data-name="Plus" aria-hidden="true">
                                                              <path fill-rule="evenodd" clip-rule="evenodd" d="M11 11V2H13V11H22V13H13V22H11V13H2V11H11Z" fill="currentColor"></path>
                                                            </svg>
                                                          </div>
                                                        </div>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <p class="titleCard-synopsis previewModal--small-text">
                                              <div class="ptrack-container">
                                                <div class="ptrack-content">Potencia tus ventas por teléfono con efectivas técnicas de comunicación y persuasión para el éxito comercial.</div>
                                              </div>
                                            </p>
                                          </div>
                                        </div>

                                        <div class="titleCard--container more-like-this-item" aria-label="1">
                                          <div class="titleCard-imageWrapper has-duration">
                                            <div class="ptrack-content">
                                              <img src={process.env.PUBLIC_URL + 'img/thumbnails/3.jpg'} alt="1" />
                                            </div>
                                            <div class="titleCard-playIcon">
                                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="titleCard-playSVG ltr-4z3qvp e1svuwfo1" data-name="Play" aria-hidden="true">
                                                <path d="M5 2.69127C5 1.93067 5.81547 1.44851 6.48192 1.81506L23.4069 11.1238C24.0977 11.5037 24.0977 12.4963 23.4069 12.8762L6.48192 22.1849C5.81546 22.5515 5 22.0693 5 21.3087V2.69127Z" fill="currentColor"></path>
                                              </svg>
                                            </div>
                                            <span class="duration ellipsized">32 capítulos</span>
                                          </div>
                                          <div class="titleCard--metadataWrapper">
                                            <div>
                                              <div class="videoMetadata--container-container">
                                                <div class="videoMetadata--container titlecard-videoMetadata videoMetadata--two-lines">
                                                  <div class="videoMetadata--first-line">
                                                    <span class="match-score-wrapper no-rating">
                                                      <div class="show-match-score rating-inner">
                                                        <div class="meta-thumb-container thumb-down">
                                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="thumb thumb-down-filled svg-icon svg-icon-thumb-thumb-down-filled ltr-4z3qvp e1svuwfo1" data-name="ThumbsDownFill" aria-hidden="true">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.593 17.7442L10.687 18.4959C10.8658 19.9265 12.0819 21 13.5236 21C13.7867 21 14 20.7867 14 20.5236V17.2868C14 17.0994 14.0527 16.9157 14.152 16.7568L16.0926 13.6519C16.3548 13.2323 16.7633 12.9248 17.2391 12.7888L19.2747 12.2072C19.704 12.0846 20 11.6922 20 11.2457V5.68387C20 5.30618 19.6938 5 19.3161 5C18.126 5 16.9565 4.68942 15.9232 4.09895L15.75 4C14.6032 3.34469 13.3053 3 11.9844 3H11H8H7.5C6.67157 3 6 3.67157 6 4.5C6 4.88418 6.14443 5.23462 6.38195 5.5H6C5.17157 5.5 4.5 6.17157 4.5 7C4.5 7.53991 4.78525 8.0132 5.21328 8.27737C4.522 8.41118 4 9.01963 4 9.75C4 10.5784 4.67157 11.25 5.5 11.25H5.67055C5.26638 11.5187 5 11.9783 5 12.5C5 13.3284 5.67157 14 6.5 14H10.875L10.593 16.2558C10.5312 16.75 10.5312 17.25 10.593 17.7442Z" fill="currentColor"></path>
                                                          </svg>
                                                        </div>
                                                        <div class="meta-thumb-container thumb-up">
                                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="thumb thumb-up-filled svg-icon svg-icon-thumb-thumb-up-filled ltr-4z3qvp e1svuwfo1" data-name="ThumbsUpFill" aria-hidden="true">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.407 6.25579L13.313 5.50407C13.1342 4.07353 11.9181 3 10.4764 3C10.2133 3 10 3.21331 10 3.47644V6.7132C10 6.90062 9.94733 7.08427 9.848 7.2432L7.90742 10.3481C7.64516 10.7677 7.23665 11.0752 6.76086 11.2112L4.72528 11.7928C4.29598 11.9154 4 12.3078 4 12.7543V18.3161C4 18.6938 4.30618 19 4.68387 19C5.874 19 7.04352 19.3106 8.07684 19.9011L8.25 20C9.39679 20.6553 10.6947 21 12.0156 21H13H16H16.5C17.3284 21 18 20.3284 18 19.5C18 19.1158 17.8556 18.7654 17.6181 18.5H18C18.8284 18.5 19.5 17.8284 19.5 17C19.5 16.4601 19.2147 15.9868 18.7867 15.7226C19.478 15.5888 20 14.9804 20 14.25C20 13.4216 19.3284 12.75 18.5 12.75H18.3294C18.7336 12.4813 19 12.0217 19 11.5C19 10.6716 18.3284 10 17.5 10H13.125L13.407 7.74421C13.4688 7.24999 13.4688 6.75001 13.407 6.25579Z" fill="currentColor"></path>
                                                          </svg>
                                                        </div>
                                                      </div>
                                                    </span>
                                                  </div>
                                                  <div class="videoMetadata--second-line">
                                                    <div class="year">2023</div>
                                                    <span class="maturity-rating "><span class="maturity-number">HD</span></span>
                                                  </div>
                                                </div>
                                                <div>
                                                  <div class="ltr-bjn8wh">
                                                    <div class="ptrack-content">
                                                      <button aria-label="Agregar a Mi lista" class="color-supplementary hasIcon round ltr-11vo9g5" type="button">
                                                        <div class="ltr-1st24vv">
                                                          <div class="small ltr-iyulz3">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="ltr-4z3qvp e1svuwfo1" data-name="Plus" aria-hidden="true">
                                                              <path fill-rule="evenodd" clip-rule="evenodd" d="M11 11V2H13V11H22V13H13V22H11V13H2V11H11Z" fill="currentColor"></path>
                                                            </svg>
                                                          </div>
                                                        </div>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <p class="titleCard-synopsis previewModal--small-text">
                                              <div class="ptrack-container">
                                                <div class="ptrack-content">Aprende la ruta de los 5 pasos clave para garantizar ventas exitosas. Potencia tus habilidades en ventas y alcanza el éxito.</div>
                                              </div>
                                            </p>
                                          </div>
                                        </div>

                                      </div>
                                      <div class="section-divider collapsed mb-5">
                                        <button aria-label="expand section" class="color-supplementary section-expandButton hasIcon round ltr-11vo9g5" type="button">
                                          <div class="ltr-1st24vv">
                                            <div class="small ltr-iyulz3" role="presentation">
                                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="ltr-4z3qvp e1svuwfo1" data-name="ChevronDown" aria-hidden="true">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 15.5859L19.2928 8.29297L20.7071 9.70718L12.7071 17.7072C12.5195 17.8947 12.2652 18.0001 12 18.0001C11.7347 18.0001 11.4804 17.8947 11.2928 17.7072L3.29285 9.70718L4.70706 8.29297L12 15.5859Z" fill="currentColor"></path>
                                              </svg>
                                            </div>
                                          </div>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </React.Fragment>
              ))}
            </Slider>
          </div>
        </div>
      </div >
    </div >
  );
};

export default Carousel;
