import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Splash = () => {
  const navigate = useNavigate(); // Usar 'useNavigate' en lugar de 'useHistory'
  const { user } = useAuth();

  useEffect(() => {
    const splashTimeout = setTimeout(() => {
      // Redirige al usuario según si está autenticado o no
      navigate(user ? '/' : '/login');
    }, 3000); // Retraso de 2 segundos

    return () => clearTimeout(splashTimeout);
  }, [navigate, user]);

  return (
    <div className="splash">
      <img src={process.env.PUBLIC_URL + '/img/splash-logo.png'} alt="Masflix Logo" />
    </div>
  );
};

export default Splash;
