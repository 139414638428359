// MyList.js
import React from 'react';

const MyList = () => {
  return (
    <div>
      <h2>Mi Lista</h2>
      {/* Contenido de la página de mi lista */}
    </div>
  );
};

export default MyList;
