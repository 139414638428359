// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Splash from './pages/Splash';
import Home from './pages/home';
import Login from './pages/Login';
import Courses from './pages/Courses'; 
import Chapters from './pages/Chapters'; 
import Popular from './pages/Popular'; 
import MyList from './pages/MyList'; 

function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Simula la autenticación con el usuario temporal después de un breve tiempo
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsAuthenticated(true);
    }, 1000); // Puedes ajustar según sea necesario

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route
            path="/"
            element={isAuthenticated ? <Home /> : <Navigate to="/splash" replace />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/splash" element={<Splash />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/chapters" element={<Chapters />} />
          <Route path="/popular" element={<Popular />} />
          <Route path="/mylist" element={<MyList />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
