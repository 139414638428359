// Popular.js
import React from 'react';

const Popular = () => {
  return (
    <div>
      <h2>Populares</h2>
      {/* Contenido de la página de populares */}
    </div>
  );
};

export default Popular;
