import React, { useState, useRef, useEffect } from 'react';
import data from '../data/data.json';

const SearchPage = ({ setIsSearchOpen }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef(null);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  const filterVideos = (videos, term) => {
    const lowerCaseTerm = term.toLowerCase();
    const filteredVideos = videos.reduce((accumulator, videoCategory) => {
      const filteredContents = videoCategory.contents.filter(
        (content) =>
          content.title.toLowerCase().includes(lowerCaseTerm) ||
          videoCategory.title.toLowerCase().includes(lowerCaseTerm)
      );

      if (filteredContents.length > 0) {
        accumulator.push({ ...videoCategory, contents: filteredContents });
      }

      return accumulator;
    }, []);

    // Agrupar videos con el mismo nombre
    const uniqueVideos = filteredVideos.reduce((unique, current) => {
      const existing = unique.find((video) => video.contents[0].title === current.contents[0].title);

      if (!existing) {
        unique.push(current);
      }

      return unique;
    }, []);

    return uniqueVideos;
  };

  useEffect(() => {
    // Enfocar el input cuando el componente se monta
    if (inputRef.current) {
      inputRef.current.focus();
    }

    // Mostrar que estamos buscando
    if (setIsSearchOpen) {
      setIsSearchOpen(true);
    }

    // Limpiar al desmontar
    return () => {
      if (setIsSearchOpen) {
        setIsSearchOpen(false);
      }
    };
  }, [setIsSearchOpen]);

  const videos = data.videos;
  const filteredVideos = filterVideos(videos, searchTerm);
  const showResults = searchTerm.length > 0;

  return (
    <div className="search-page">
      <input
        ref={inputRef}
        type="text"
        placeholder="Buscar cursos..."
        value={searchTerm}
        onChange={handleSearch}
      />

      {showResults && (
        <div>
          {filteredVideos.length > 0 ? (
            <div>
              <h2>Contenido para explorar</h2>
              <div className="suggested-courses">
                {filteredVideos.map((videoCategory) => (
                  <div key={videoCategory.title} className="search-result">
                    <h3>{videoCategory.title}</h3>
                    {videoCategory.contents.map((content) => (
                      <div key={content.id} className="search-result-item">
                        <h4>{content.title}</h4>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="sin-resultados">
              <p>{`La búsqueda de «${searchTerm}» no arrojó coincidencias.`}</p>
              <p>Sugerencias:</p>
              <ul>
                <li>Intenta con otras palabras clave</li>
                <li>
                  Intenta con el título de una película o serie, o con un nombre del elenco o la
                  dirección
                </li>
                <li>Intenta con un género, como comedia, romance, deportes o drama</li>
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchPage;
